<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">Enviar Alerta</h6>
    </b-row>
    <b-row class="justify-content-end mb-3">
      <b-col xl="2" md="2">
        <b-form-group class="mt-1">
          <select class="form-select form-select-sm rounded-0" v-model="type">
            <option :value="null" disabled selected>Tipo de agente</option>
            <option :value="null"></option>
            <option v-for="(item, l) in lawFirmTypes" :key="l" :value="item.key">
              {{ $t(`law-firm-types.${item.label}`) }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col xl="3" md="3">
        <b-input-group class="mt-1">
          <label class="form-title mt-1 me-2">Sociedades registradas:</label>
          <b-form-input placeholder="Desde" class="rounded-0" size="sm" v-model="fromCountLegalEntities"
            type="number"></b-form-input>
          <b-form-input placeholder="Hasta" class="rounded-0" size="sm" v-model="toCountLegalEntities"
            type="number"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xl="3" md="3">
        <b-input-group class="mt-1">
          <label class="form-title mt-1 me-2">Sociedades en RP:</label>
          <b-form-input placeholder="Desde" class="rounded-0" size="sm" v-model="fromCountLegalEntitiesRp"
            type="number"></b-form-input>
          <b-form-input placeholder="Hasta" class="rounded-0" size="sm" v-model="toCountLegalEntitiesRp"
            type="number"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col xl="2" md="2">
        <b-form-group class="mt-1">
          <select class="form-select form-select-sm rounded-0" v-model="searchStatus">
            <option :value="null" disabled selected>Estado</option>
            <option :value="null"></option>
            <option value="0">Activo</option>
            <option value="1">Inactivo</option>
          </select>
        </b-form-group>
      </b-col>
      <b-col xl="2" md="2">
        <b-input-group class="mt-1">
          <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-end mb-3">
      <b-col xl="3" md="3">
        <b-input-group class="mt-1 justify-content-end">
          <label class="form-title mt-1 me-2">Obtener:</label>
          <b-form-input placeholder="Obtener" class="rounded-0 me-3" size="sm" v-model="setTake"
            type="number"></b-form-input>
          <b-button class="main-btn" size="sm" @click="refreshTable">
            Aplicar filtro
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table ref="aSendTable" responsive stacked="md" small hover select-mode="single" selectable show-empty
            empty-text="No hay registros que mostrar" :filter="filter" :busy.sync="isBusy" :fields="fields"
            :items="retrieveData" :current-page="currentPage" :per-page="take" @filtered="onFiltered"
            @row-clicked="rowClicked">
            <template #head(selected)>
              <b-checkbox v-model="selectAllChecked" @change="selectAll"></b-checkbox>
            </template>
            <template #cell(selected)="data">
              <input type="checkbox" :checked="data.item.checked" @change="rowClicked(data.item)" />
            </template>
            <template #cell(lawFirmType)="data">
              {{ $t(`law-firm-types.${data.item.lawFirmType}`) }}
            </template>
            <template #cell(curStatus)="data">
              {{ $t(`cur-status.${data.item.curStatus}`) }}
            </template>
            <template #cell(creationDate)="data">
              {{ formattedDate(data.item.creationDate) }}
            </template>
            <template #cell(firstRegistrationDate)="data">
              {{ formattedDate(data.item.firstRegistrationDate) }}
            </template>
            <template #cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button size="sm" @click="editLegalEntityRP(data.item)" variant="success">
                  <b-icon icon="book" v-b-tooltip.hover title="Sociedades en Registro Público" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <label class="form-title">Total de registros:</label> {{ rows }}
      </b-col>
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
          class="my-0 justify-content-end">
          <template #first-text>
            <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
          </template>
          <template #last-text>
            <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <b-row class="justify-content-end mb-3">
      <b-col>
        <b-input-group class="justify-content-end">
          <b-button class="main-btn" size="sm" @click="selectAlert">
            Seleccionar Alerta
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal v-model="selectModal" content-class="rounded-0" dialog-class="long-modal" header-class="border-0" centered
      hide-header hide-footer no-close-on-backdrop>
      <div class="container">
        <b-form-group class="mt-3">
          <select class="form-select form-select-sm rounded-0" v-model="alertType" @change="selectAlertType">
            <option :value="null" disabled selected>Tipo de alerta</option>
            <option :value="null"></option>
            <option :value="0">Manual</option>
            <option :value="1">Dinámico</option>
          </select>
        </b-form-group>

        <div v-if="alertType === 0">
          <div v-if="alertForm">
            <AlertManualForm :alertManual="alertForm" />
          </div>
        </div>

        <div v-if="alertType === 1">
          <b-form-group class="mt-3">
            <select class="form-select form-select-sm rounded-0" v-model="alertForm" @change="setDynamicForm">
              <option :value="null" disabled selected>Tipo de mensaje</option>
              <option :value="null"></option>
              <option v-for="(item, t) in alertList" :key="t" :value="item">
                {{ getAlertDynamicType(item.type) }}
              </option>
            </select>
          </b-form-group>

          <div v-if="alertForm">
            <div v-if="alertForm.type === 1">
              <label class="form-title mt-3">
                Puede solicitar la desvinculación de un número de ficha o folio.
              </label>
              <b-row class="mt-3">
                <b-col>
                  <b-form-group>
                    <label class="form-title">
                      {{ $t("general.society-type") }}
                    </label>
                    <select class="form-select form-select-sm rounded-0" v-model="societyType">
                      <option value="SA">
                        {{ $t("society-types.sociedad anonima") }}
                      </option>
                      <option value="FU">
                        {{ $t("society-types.fundacion de interes propio") }}
                      </option>
                      <option value="LI">
                        {{ $t("society-types.sociedad limitada") }}
                      </option>
                      <option value="EX">
                        {{ $t("society-types.sociedad extranjera") }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label class="form-title"> Número de ficha o folio </label>
                    <b-input-group>
                      <b-form-input v-model="folio" size="sm" required class="rounded-0" />
                      <b-input-group-append>
                        <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
                          class="d-inline-block">
                          <b-button size="sm" class="btn main-btn" v-b-tooltip.hover
                            title="Agregar la Firma/Abogado que posee la sociedad" @click="addDisconnectedLegalEntity">
                            Agregar
                          </b-button>
                        </b-overlay>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <table class="
                      table
                      b-table
                      table-hover table-sm
                      b-table-stacked-md
                      b-table-selectable
                      b-table-select-single
                    ">
                    <thead>
                      <tr>
                        <th>Firma/Abogado</th>
                        <th>CUR</th>
                        <th>Folio a desvincular</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, d) in disconnectedLegalEntities" :key="d">
                        <td>{{ item.name }}</td>
                        <td>{{ item.cur }}</td>
                        <td>{{ item.societyType }} {{ item.folio }}</td>
                        <td>
                          <b-icon-x-circle @click="removeDisconnectedLegalEntities(d)" size="sm" variant="danger" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col>
                <b-form-group class="mt-3">
                  <label class="form-title">Fecha de envío</label>
                  <DatePicker v-model="alertForm.sendDate" :disabledBeforeToday="true" @input="changeSendDate" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="mt-3">
                  <label class="form-title">Hora de envío</label>
                  <TimePicker v-model="alertForm.sendTime" @input="changeSendTime" />
                </b-form-group>
              </b-col>
            </b-row>

            <AlertDynamicForm :alertDynamic="alertForm" :sendDate="setSendDate" :sendTime="setSendTime" />
          </div>
        </div>

        <div class="text-end">
          <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
            class="d-inline-block">
            <b-button class="my-4 main-btn me-3" @click="sendAlert" :disabled="sending">
              <span>Enviar</span>
            </b-button>
          </b-overlay>
          <b-button class="my-4 btn btn-danger" @click="close">
            <span>Cancelar</span>
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmation" content-class="rounded-0" dialog-class="long-modal" body-bg-variant="light"
      hide-header-close centered no-close-on-backdrop hide-footer hide-header>
      <div class="container mt-3">
        <b-row class="text-center p-3">
          <b-col>
            <p class="details-title">
              <strong v-if="alertForm">
                Esta a punto de enviar un mensaje de
                <u>{{ getAlertDynamicType(alertForm.type) }}</u>
                a los ({{ selectAllChecked ? this.rows : lawFirmIds.length }}) Agentes Residentes Seleccionados
              </strong>
            </p>
            <p class="details-title">
              Primero revisé la información a enviar. Si está de acuerdo,
              presione clic en aceptar.
            </p>
            <p class="details-title">
              <strong>Ejemplo de mensaje a enviar:</strong><br /><br />
              <span v-html="messageExample" />
            </p>
          </b-col>
        </b-row>

        <b-row class="text-center mt-4">
          <b-col>
            <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
              class="d-inline-block">
              <b-button class="main-btn me-3" size="sm" @click="accept" :disabled="loading">Aceptar</b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="$bvModal.hide('confirmation')">Cancelar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- Modal RP-->
    <b-modal v-model="modalRP" content-class="rounded-0" dialog-class="modal-sm" header-class="border-0" centered
      hide-header hide-footer no-close-on-backdrop>
      <div class="container">
        <div v-if="LawFirmForLegalEntitiesRP" class="mt-3 mb-3">
          <h6>{{ LawFirmForLegalEntitiesRP.name }}</h6>
          <h6>{{ LawFirmForLegalEntitiesRP.cur }}</h6>
        </div>

        <b-form-group class="mt-3">
          <label class="form-title">
            Cantidad de Sociedades en Registro Público*</label>
          <b-form-input class="rounded-0" type="number" v-model="countLegalEntitiesRP" />
        </b-form-group>

        <div class="text-end">
          <b-overlay :show="sending" rounded opacity="0.7" spinner-small spinner-variant="primary"
            class="d-inline-block">
            <b-button class="my-4 main-btn me-3" @click="saveModalRP" :disabled="sending">
              Guardar
            </b-button>
          </b-overlay>
          <b-button class="my-4 btn btn-danger" @click="closeModalRP">
            <span>Cancelar</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { lawFirmTypes } from "@/config/law-firm-types";
import { alertTypes } from "@/config/alertTypes";
import { alertDynamicType } from "@/config/alertDynamicType";
import { expirationPeriodTypes } from "@/config/expirationPeriodTypes";
import { calculateTimeDifference, dynamicMessage } from "@/services/alerts";
import { mapActions } from "vuex";
import AlertManualForm from "./AlertManualForm.vue";
import AlertDynamicForm from "./AlertDynamicForm.vue";
import DatePicker from "@/components/widgets/DatePicker.vue";
import TimePicker from "@/components/widgets/TimePicker.vue";
import moment from "moment";

export default {
  name: "send-alert",
  components: {
    AlertManualForm,
    AlertDynamicForm,
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      lawFirmTypes: lawFirmTypes,
      alertTypes: alertTypes,
      alertDynamicType: alertDynamicType,
      expirationPeriodTypes: expirationPeriodTypes,
      search: null,
      searchStatus: null,
      fromCountLegalEntities: null,
      toCountLegalEntities: null,
      fromCountLegalEntitiesRp: null,
      toCountLegalEntitiesRp: null,
      selectAllChecked: false,
      type: null,
      setTake: 10,
      isBusy: false,
      fields: [
        {
          key: "selected",
          label: "",
          class: "text-center",
        },
        { key: "name", label: "Abogado/Firma" },
        {
          key: "creationDate",
          label: this.$t("general.creation-date"),
        },
        { key: "lawFirmType", label: this.$t("general.law-firm-type") },
        { key: "cur", label: "CUR" },
        { key: "curStatus", label: this.$t("general.cur-status") },
        {
          key: "firstRegistrationDate",
          label: "Fecha de Primer AR registrado",
        },
        {
          key: "countLegalEntities",
          label: "Sociedades registradas",
          class: "text-center",
        },
        {
          key: "numberLegalEntityInPublicRegistry",
          label: "Sociedades en Registro Público",
          class: "text-center",
        },
        { key: "action", label: "Acción" },
      ],
      filter: null,
      currentPage: 1,
      take: 10,
      rows: 0,
      items: [],
      selectModal: false,
      modalRP: false,
      sending: false,
      loading: false,
      alertType: null,
      alertForm: null,
      alertList: [],
      messageExample: null,
      lawFirmIds: [],
      countLegalEntitiesRP: null,
      LawFirmForLegalEntitiesRP: null,
      societyType: null,
      folio: null,
      disconnectedLegalEntities: [],
      setSendDate: null,
      setSendTime: null,
      context: null,
    };
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    onFiltered() { },
    rowClicked(item) {
      item.checked = !item.checked;
    },
    async retrieveData(context) {
      try {
        this.isBusy = true;
        this.context = context;
        let payload = {
          page: context.currentPage,
          take: this.take,
          search: context.filter,
          status: this.searchStatus,
          fromCountLegalEntities: this.fromCountLegalEntities,
          toCountLegalEntities: this.toCountLegalEntities,
          fromCountLegalEntitiesRp: this.fromCountLegalEntitiesRp,
          toCountLegalEntitiesRp: this.toCountLegalEntitiesRp,
          type: this.type,
        };
        let response = await RepositoryFactory.alertRepository.getLawFirms(
          payload
        );
        this.rows = response.data.total;
        this.items = response.data.items;

        this.items.forEach((item) => {
          item.checked = this.selectAllChecked;
          item.name = item.name.toUpperCase()
        });

        return this.items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    async retrieveAllData() {
      try {
        this.isBusy = true;
        let payload = {
          page: this.context.currentPage,
          take: this.rows,
          search: this.context.filter,
          status: this.searchStatus,
          fromCountLegalEntities: this.fromCountLegalEntities,
          toCountLegalEntities: this.toCountLegalEntities,
          fromCountLegalEntitiesRp: this.fromCountLegalEntitiesRp,
          toCountLegalEntitiesRp: this.toCountLegalEntitiesRp,
          type: this.type,
        };
        let response = await RepositoryFactory.alertRepository.getLawFirms(
          payload
        );
        this.rows = response.data.total;
        this.items = response.data.items;

        this.items.forEach((item) => {
          item.checked = this.selectAllChecked;
          item.name = item.name ? item.name.toUpperCase() : '';
        });

        return this.items;
      } catch (error) {
        window.console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    formattedDate(date) {
      return date != null
        ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
        : "";
    },
    filterTable() {
      this.currentPage = 1;
      this.filter = this.search;
      this.take = this.setTake;
    },
    refreshTable() {
      this.filterTable();
      this.$refs.aSendTable.refresh();
    },
    async selectAll() {
      this.refreshTable();
    },
    selectAlert() {
      this.alertType = null;
      this.lawFirmIds = [];
      this.items.forEach((item) => {
        if (item.checked === true) {
          this.lawFirmIds.push(item.id);
        }
      });

      if (this.lawFirmIds.length === 0) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Debe seleccionar al menos un agente residente.",
        });
        return;
      }

      this.selectModal = true;
    },
    close() {
      this.selectModal = false;
    },
    getAlertDynamicType(value) {
      const t = this.alertDynamicType.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    async selectAlertType() {
      this.alertList = [];
      this.alertForm = null;
      this.disconnectedLegalEntities = [];
      try {
        switch (this.alertType) {
          case 0:
            this.alertForm = {
              message: null,
              sendMail: false,
            };
            break;
          case 1:
            this.alertForm = {
              type: null,
              message: null,
              expirationPeriod: null,
              expirationPeriodType: null,
              expirationMessage: null,
              emailMessage: null,
              sendMail: false,
              needConfirmation: false,
              sendDate: null,
              sendTime: null,
              payload: null,
              endDate: null,
              endHour: null,
            };
            var response =
              await RepositoryFactory.alertRepository.getAllDynamic();
            this.alertList = response.data;
            break;
          default:
            break;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    changeSendDate(value) {
      this.setSendDate = value;
    },
    changeSendTime(value) {
      this.setSendTime = value;
    },
    setDynamicForm() {
      if (this.alertForm !== null) {
        this.alertForm.sendDate = moment().format("YYYY-MM-DD");
        this.alertForm.sendTime = {
          A: moment().format("A"),
          hh: moment().format("hh"),
          mm: moment().format("mm"),
          ss: moment().format("ss"),
        };
        this.alertForm.endDate = null;
        this.alertForm.endHour = null;
      }
    },
    async accept() {
      try {
        this.loading = true;

        if (this.selectAllChecked) {
          const result = await this.retrieveAllData();
          this.lawFirmIds = result.map(item => item.id);
        }

        switch (this.alertType) {
          case 0:
            var payloadManual = {
              lawFirmIds: this.lawFirmIds,
              alertManualNotification: {
                message: this.alertForm.message,
                sendMail: this.alertForm.sendMail,
              },
            };
            await RepositoryFactory.alertRepository.saveManualNotification(
              payloadManual
            );
            break;
          case 1:
            if (this.alertForm.expirationPeriod === "") {
              this.alertForm.expirationPeriod = null;
            }
            if (this.alertForm.expirationPeriodType === "") {
              this.alertForm.expirationPeriodType = null;
            }

            var sendDate = null;

            if (
              this.alertForm.sendDate != null &&
              this.alertForm.sendTime != null
            ) {
              sendDate = moment(this.alertForm.sendDate);
              var hour = parseInt(this.alertForm.sendTime.hh);
              var minute = parseInt(this.alertForm.sendTime.mm);
              var second = parseInt(this.alertForm.sendTime.ss);

              if (this.alertForm.sendTime.A === "PM") {
                hour += 12;
              }

              sendDate.hours(hour);
              sendDate.minutes(minute);
              sendDate.seconds(second);
            }

            var expiredDate = null;

            if (
              this.alertForm.endDate != null &&
              this.alertForm.endHour != null
            ) {
              expiredDate = moment(this.alertForm.endDate);
              var ehour = parseInt(this.alertForm.endHour.hh);
              var eminute = parseInt(this.alertForm.endHour.mm);
              var esecond = parseInt(this.alertForm.endHour.ss);

              if (this.alertForm.endHour.A === "PM") {
                hour += 12;
              }

              expiredDate.hours(ehour);
              expiredDate.minutes(eminute);
              expiredDate.seconds(esecond);
            }

            var payload = null;

            if (this.alertForm.type === 1) {
              payload = JSON.stringify(this.disconnectedLegalEntities);
            }

            var payloadDynamic = {
              lawFirmIds: this.lawFirmIds,
              alertDynamicNotification: {
                type: this.alertForm.type,
                message: this.alertForm.message,
                expirationPeriod: this.alertForm.expirationPeriod,
                expirationPeriodType: this.alertForm.expirationPeriodType,
                expirationMessage: this.alertForm.expirationMessage,
                emailMessage: this.alertForm.emailMessage,
                sendMail: this.alertForm.sendMail,
                needConfirmation: this.alertForm.needConfirmation,
                sendDate: sendDate,
                expiredDate: expiredDate,
                payload: payload,
              },
            };
            await RepositoryFactory.alertRepository.saveDynamicNotification(
              payloadDynamic
            );
            break;
          default:
            break;
        }
        this.selectModal = false;
        this.alertType = null;
        this.refreshTable();
      } catch (error) {
        console.log("error");
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: `Error al consumir servicio`,
        });
      } finally {
        this.loading = false;
        this.$bvModal.hide("confirmation");
      }
    },
    sendAlert() {
      if (this.alertType === null) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Debe seleccionar el tipo de alerta.",
        });
        return;
      }

      if (this.alertForm === null) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Debe seleccionar el tipo de mensaje.",
        });
        return;
      }

      if (this.alertType === 1 && this.alertForm.type === 1) {
        if (this.lawFirmIds.length > 1) {
          this.messageDisconnectedLegalEntity();
        }
        if (this.disconnectedLegalEntities.length === 0) {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "El folio ha desvincular es requerido",
          });
          return;
        }
      }

      this.messageExample = null;
      switch (this.alertType) {
        case 0:
          if (this.lawFirmIds.length > 0 && this.alertForm !== null) {
            this.messageExample = this.alertForm.message;
            this.$bvModal.show("confirmation");
          }
          break;
        case 1:
          this.items.forEach((item) => {
            if (item.checked === true) {
              this.setMessageExample(item);
            }
          });

          if (this.lawFirmIds.length > 0 && this.messageExample !== null) {
            this.$bvModal.show("confirmation");
          }
          break;
        default:
          break;
      }
    },
    setMessageExample(item) {
      if (this.messageExample === null && this.alertForm) {
        if (this.alertType === 1) {
          const date = new Date();
          var expirationPeriodType = this.getExpirationPeriodType(
            this.alertForm.expirationPeriodType
          );
          let expirationPeriod = `${this.alertForm.expirationPeriod} ${expirationPeriodType}`;
          let differenceDateSent = calculateTimeDifference(
            date,
            this.alertForm.expirationPeriod,
            this.alertForm.expirationPeriodType
          );
          let folios = "";

          if (this.alertForm.type === 1) {
            folios = this.disconnectedLegalEntities.map(
              (entity) => entity.societyType + " " + entity.folio
            );
          }

          this.messageExample = dynamicMessage(
            this.alertForm.message,
            date,
            item.countLegalEntities,
            expirationPeriod,
            differenceDateSent,
            expirationPeriodType,
            folios
          );
        }
      }
    },
    getExpirationPeriodType(value) {
      const t = this.expirationPeriodTypes.find((x) => x.key === value);
      return t?.label ? this.$t(`alert.${t.label}`) : "";
    },
    editLegalEntityRP(item) {
      this.modalRP = true;
      this.countLegalEntitiesRP = item.numberLegalEntityInPublicRegistry;
      this.LawFirmForLegalEntitiesRP = item;
    },
    closeModalRP() {
      this.LawFirmForLegalEntitiesRP = null;
      this.countLegalEntitiesRP = null;
      this.modalRP = false;
    },
    async saveModalRP() {
      try {
        this.sending = true;
        var payload = {
          numberLegalEntityInPublicRegistry: {
            number: this.countLegalEntitiesRP,
            lawFirmId: this.LawFirmForLegalEntitiesRP.id,
          },
        };
        var response =
          await RepositoryFactory.alertRepository.saveNumberLegalEntityInPublicRegistry(
            payload
          );

        if (response.data == "" || response.data == null) {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message:
              "No hay registros la tabla de sociedades de grupos correspondiente para esta cantidad. Puede agregarlo en Configuración de Grupo de Sociedades",
          });
          return;
        }

        this.closeModalRP();
        this.refreshTable();
      } catch (error) {
        console.log(error);
      } finally {
        this.sending = false;
      }
    },
    removeDisconnectedLegalEntities(index) {
      this.disconnectedLegalEntities.splice(index, 1);
    },
    messageDisconnectedLegalEntity() {
      this.openModal();
      this.setModalInfo({
        title: "Aviso",
        message:
          "Para este tipo de alerta solo puede seleccionar una sola Firma/Abogado. Ya que se le informara cuando se realice la desvinculación de un folio y este solo lo puede tener una sola Firma/Abogado.",
      });
      return;
    },
    async addDisconnectedLegalEntity() {
      try {
        if (this.lawFirmIds.length > 1) {
          this.messageDisconnectedLegalEntity();
        }

        if (!this.societyType || !this.folio) {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "El tipo y el folio es requerido",
          });
          return;
        }
        var response =
          await RepositoryFactory.alertRepository.getLawFirmByFolio(
            this.societyType,
            this.folio
          );

        if (response.status === 200 && response.data) {
          if (this.lawFirmIds[0] === response.data.id) {
            this.openModal();
            this.setModalInfo({
              title: "Aviso",
              message:
                "Este folio ya está asociado a la Firma/Abogado seleccionado",
            });
            return;
          }

          var lawFirm = {
            lawFirmId: response.data.id,
            name: response.data.name,
            cur: response.data.cur,
            societyType: this.societyType,
            folio: this.folio,
            NotifyLawFirmId: this.lawFirmIds[0],
          };

          var exists = this.disconnectedLegalEntities.some(
            (entity) =>
              entity.societyType === lawFirm.societyType &&
              entity.folio === lawFirm.folio
          );

          if (!exists) {
            this.disconnectedLegalEntities.push(lawFirm);
          }

          this.societyType = null;
          this.folio = null;
        } else {
          this.openModal();
          this.setModalInfo({
            title: "Aviso",
            message: "El folio no esta registrado o habilitado",
          });
          return;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
